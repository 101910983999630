import React from 'react';
import { Table, Button, Tooltip, Space } from 'antd';
import BN from 'bn.js';
import { formatValue } from '../utils/formatValue';
import useApprovals from '../hooks/useApprovals';
import useExitPosition from '../hooks/useExitPosition';
import ApprovalButtons from './ApprovalButtons';
import ExitButton from './ExitButton';
import { colors, fonts } from '../theme';

const CONTRACT_ADDRESS = "0xb5a0623292f7b67d1f8903d3ea6fbd25be9a67a4";

const supportedProtocols = [
  { name: 'crvUSD', link: 'https://crvusd.curve.fi/' },
  { name: 'crvUSD Lend', link: 'https://lend.curve.fi/' },
];

const LoanTable = ({ loans }) => {
  const { approvals, approving, handleApprove } = useApprovals(loans, CONTRACT_ADDRESS);
  const { exiting, exitPosition } = useExitPosition(CONTRACT_ADDRESS);

  const columns = [
    {
      title: 'Controller Address',
      dataIndex: 'contract',
      key: 'contract',
      render: (text, record) => (
        <a href={`https://etherscan.io/address/${record.contract}`} target="_blank" rel="noopener noreferrer" style={{ color: colors.woodBrown }}>
          {record.contract}
        </a>
      ),
    },
    {
      title: 'Collateral',
      dataIndex: 'collateral',
      key: 'collateral',
      render: (text, record) => `${formatValue(record.collateral, record.collateralToken.decimals)} ${record.collateralToken?.symbol || ''}`,
    },
    {
      title: 'Collateral crvUSD',
      dataIndex: 'crvUSDReceived',
      key: 'crvUSDReceived',
      render: (text, record) => `$${formatValue(record.crvUSDReceived, 18)}`,
    },
    {
      title: 'Borrowed crvUSD',
      dataIndex: 'debt',
      key: 'debt',
      render: (text, record) => `$${formatValue(record.debt, 18)}`,
    },
    {
      title: 'Health',
      dataIndex: 'health',
      key: 'health',
      render: (text, record) => `${formatValue(new BN(record.health).mul(new BN(100)), 18, 2)}%`,
    },
    {
      title: 'Approvals',
      key: 'approvals',
      render: (text, record) => (
        <ApprovalButtons 
          loan={record}
          approvals={approvals}
          approving={approving}
          onApprove={handleApprove}
          contractAddress={CONTRACT_ADDRESS}
        />
      ),
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (text, record) => (
        <ExitButton 
          loan={record}
          exiting={exiting}
          onExit={exitPosition}
          approvals={approvals}
        />
      ),
    },
  ];

  return (
    <div>
      <Space style={{ marginBottom: '20px', display: 'flex', alignItems: 'center' }}>
        <h2 style={{ 
          color: colors.woodBrown, 
          fontFamily: fonts.header,
          margin: 0,
        }}>
          Active Loans
        </h2>
        {supportedProtocols.map((protocol) => (
          <Tooltip key={protocol.name} title={protocol.link}>
            <Button 
              type="default"
              style={{
                backgroundColor: colors.lightWood,
                borderColor: colors.woodBrown,
                color: colors.woodBrown,
                fontFamily: fonts.body,
              }}
            >
              {protocol.name}
            </Button>
          </Tooltip>
        ))}
      </Space>
      <Table 
        dataSource={loans} 
        columns={columns} 
        rowKey="contract"
        style={{ 
          borderRadius: '8px',
          overflow: 'hidden'
        }}
        pagination={{
          style: { 
            padding: '10px'
          }
        }}
        rowClassName={(record, index) => index % 2 === 0 ? 'even-row' : 'odd-row'}
      />
    </div>
  );
};

// Add this style to your component to handle hover effect
const style = document.createElement('style');
style.textContent = `
  .table-row:hover td {
    background-color: #e0d6c2 !important;
  }
`;
document.head.appendChild(style);

export default LoanTable;