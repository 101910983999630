import { useState, useEffect } from 'react';
import BN from 'bn.js';
import controllerABI from '../abis/ControllerABI.json';
import erc20ABI from '../abis/ERC20.json';
import loanContractAddresses from '../config/loanContracts';

const CRVUSD_ADDRESS = "0xf939E0A03FB07F59A73314E73794Be0E57ac1b4E";

const useLoanData = (account, web3) => {
  const [loans, setLoans] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchLoans = async () => {
      const loansData = [];
      const errors = [];
      for (let address of loanContractAddresses) {
        try {
          const contract = new web3.eth.Contract(controllerABI, address);
          const userState = await contract.methods.user_state(account).call();
          const collateral = new BN(userState[0]);
          const crvUSDReceived = new BN(userState[1]);
          const debt = new BN(userState[2]);

          if (debt.gt(new BN(0))) {
            const health = new BN(await contract.methods.health(account, true).call());

            const collateralTokenAddress = await contract.methods.collateral_token().call();
            
            // Try to get borrowed token address, default to crvUSD if not available
            let borrowedTokenAddress;
            try {
              borrowedTokenAddress = await contract.methods.borrowed_token().call();
            } catch (err) {
              console.warn(`borrowed_token() not available for contract ${address}, defaulting to crvUSD`);
              borrowedTokenAddress = CRVUSD_ADDRESS;
            }

            const collateralTokenContract = new web3.eth.Contract(erc20ABI, collateralTokenAddress);
            const tokenSymbol = await collateralTokenContract.methods.symbol().call();
            const tokenDecimals = new BN(await collateralTokenContract.methods.decimals().call());

            loansData.push({
              contract: address,
              collateral: collateral,
              crvUSDReceived: crvUSDReceived,
              debt: debt,
              health: health,
              collateralToken: {
                symbol: tokenSymbol,
                decimals: tokenDecimals.toString(),
                address: collateralTokenAddress
              },
              borrowedTokenAddress
            });
          }
        } catch (err) {
          console.error(`Error fetching data from contract ${address}:`, err);
          errors.push(`Error fetching data from contract ${address}: ${err.message}`);
        }
      }
      setLoans(loansData);
      setError(errors.length > 0 ? errors.join('; ') : null);
      setLoading(false);
    };

    if (account && web3) {
      fetchLoans();
    }
  }, [account, web3]);

  return { loans, loading, error };
};

export default useLoanData;