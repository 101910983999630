import React from 'react';
import { Button } from 'antd';
import BN from 'bn.js';

const ExitButton = ({ loan, exiting, onExit, approvals }) => {
  const isInSoftLiquidation = loan.collateral.gt(new BN(0)) && loan.crvUSDReceived.gt(new BN(0));
  const isApproved = approvals[loan.contract]?.collateral && approvals[loan.contract]?.crvUSD;

  if (isInSoftLiquidation) {
    return (
      <Button 
        type="default" 
        disabled={true}
      >
        In soft liquidation
      </Button>
    );
  }

  if (!isApproved) {
    return (
      <Button 
        type="default" 
        disabled={true}
      >
        Need approvals
      </Button>
    );
  }

  return (
    <Button 
      type="primary" 
      onClick={() => onExit(loan)}
      loading={exiting[loan.contract]}
      disabled={exiting[loan.contract]}
    >
      Exit Now
    </Button>
  );
};

export default ExitButton;