import React from 'react';
import useLoanData from '../hooks/useLoanData';
import LoanTable from './LoanTable';

const LoanChecker = ({ account, web3 }) => {
  const { loans, loading, error } = useLoanData(account, web3);

  return (
    <div>
      {loading && <p>Loading loans...</p>}
      {error && <p>{error}</p>}
      {!loading && !error && <LoanTable loans={loans} account={account} />}
    </div>
  );
};

export default LoanChecker;
