// src/theme.js

export const colors = {
  woodBrown: '#8B4513',
  goldCoin: '#FFD700',
  teaGreen: '#D0F0C0',
  lightWood: '#DEB887',
  darkWood: '#5D4037'
};

export const fonts = {
  header: "'Silkscreen'",
  body: "'Roboto', sans-serif"
};

export const buttonStyle = {
  backgroundColor: colors.goldCoin,
  borderColor: colors.woodBrown,
  color: colors.woodBrown,
};

// You can add more theme-related constants here