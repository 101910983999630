import React from 'react';
import { Button } from 'antd';

const ApprovalButtons = ({ loan, approvals, approving, onApprove, contractAddress }) => {
  return (
    <div>
      <Button 
        onClick={() => onApprove(loan.collateralToken.address, true, loan.contract)}
        disabled={approvals[loan.contract]?.collateral || approving[loan.contract]?.collateral}
        loading={approving[loan.contract]?.collateral}
      >
        {approvals[loan.contract]?.collateral 
          ? `${loan.collateralToken.symbol} Approved` 
          : `Approve ${loan.collateralToken.symbol}`}
      </Button>
      <Button 
        onClick={() => onApprove(loan.borrowedTokenAddress, false, loan.contract)}
        disabled={approvals[loan.contract]?.crvUSD || approving[loan.contract]?.crvUSD}
        loading={approving[loan.contract]?.crvUSD}
      >
        {approvals[loan.contract]?.crvUSD ? 'crvUSD Approved' : 'Approve crvUSD'}
      </Button>
    </div>
  );
};

export default ApprovalButtons;