import { useState } from 'react';
import { message } from 'antd';
import BN from 'bn.js';
import { useWalletContext } from '../context/WalletContext';
import llamaExitABI from '../abis/LlamaExitABI.json';

const useExitPosition = (contractAddress) => {
  const { web3, account } = useWalletContext();
  const [exiting, setExiting] = useState({});

  const exitPosition = async (loan) => {
    setExiting({ ...exiting, [loan.contract]: true });
    
    try {
      const llamaExitContract = new web3.eth.Contract(llamaExitABI, contractAddress);
      
      const quoteUrl = 'https://api.odos.xyz/sor/quote/v2';
      const assembleUrl = 'https://api.odos.xyz/sor/assemble';
      const chainId = 1;

      const quoteBody = {
        chainId: chainId,
        inputTokens: [
          {
            tokenAddress: loan.collateralToken.address,
            amount: loan.collateral.toString(),
          },
        ],
        outputTokens: [
          {
            tokenAddress: loan.borrowedTokenAddress,
            proportion: 1,
          },
        ],
        userAddr: contractAddress,
      };

      const response = await fetch(quoteUrl, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(quoteBody),
      });

      if (!response.ok) throw new Error(`Error: ${response.statusText}`);
      
      const quoteData = await response.json();

      const assembleBody = {
        userAddr: contractAddress,
        pathId: quoteData.pathId,
      };

      const assembleResponse = await fetch(assembleUrl, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(assembleBody),
      });

      if (!assembleResponse.ok) throw new Error(`Error: ${assembleResponse.statusText}`);
      
      const assembleData = await assembleResponse.json();

      const swapToAddress = assembleData.transaction.to;
      const swapData = assembleData.transaction.data;

      const gasEstimate = await llamaExitContract.methods.exitPosition(
        loan.contract,
        swapToAddress,
        swapData
      ).estimateGas({ from: account });

      await llamaExitContract.methods.exitPosition(
        loan.contract,
        swapToAddress,
        swapData
      ).send({ 
        from: account,
        gas: new BN(gasEstimate).mul(new BN(110)).div(new BN(100)).toString()
      });

      message.success('Position exited successfully');
    } catch (error) {
      console.error('Exit position failed:', error);
      message.error(`Exit position failed: ${error.message}`);
    } finally {
      setExiting({ ...exiting, [loan.contract]: false });
    }
  };

  return { exiting, exitPosition };
};

export default useExitPosition;