import { useState, useEffect } from 'react';
import { message } from 'antd';
import BN from 'bn.js';
import { useWalletContext } from '../context/WalletContext';
import erc20ABI from '../abis/ERC20.json';

const useApprovals = (loans, contractAddress) => {
  const { web3, account } = useWalletContext();
  const [approvals, setApprovals] = useState({});
  const [approving, setApproving] = useState({});

  useEffect(() => {
    const checkApprovals = async () => {
      const newApprovals = {};
      for (const loan of loans) {
        const collateralToken = new web3.eth.Contract(erc20ABI, loan.collateralToken.address);
        const crvUSDToken = new web3.eth.Contract(erc20ABI, loan.borrowedTokenAddress);

        const collateralAllowance = await collateralToken.methods.allowance(account, contractAddress).call();
        const crvUSDAllowance = await crvUSDToken.methods.allowance(account, contractAddress).call();

        newApprovals[loan.contract] = {
          collateral: new BN(collateralAllowance).gte(loan.collateral),
          crvUSD: new BN(crvUSDAllowance).gte(loan.debt)
        };
      }
      setApprovals(newApprovals);
    };

    if (web3 && account && loans.length > 0) {
      checkApprovals();
    }
  }, [web3, account, loans, contractAddress]);

  const handleApprove = async (tokenAddress, isCollateral, loanContract) => {
    const token = new web3.eth.Contract(erc20ABI, tokenAddress);
    const tokenType = isCollateral ? 'collateral' : 'crvUSD';
    setApproving({ ...approving, [loanContract]: { ...approving[loanContract], [tokenType]: true } });
    
    try {
      const maxUint256 = '115792089237316195423570985008687907853269984665640564039457584007913129639935'; // 2^256 - 1
      const gasEstimate = await token.methods.approve(contractAddress, maxUint256).estimateGas({ from: account });
      
      const gasLimit = new BN(gasEstimate).mul(new BN(110)).div(new BN(100));

      await token.methods.approve(contractAddress, maxUint256).send({ 
        from: account,
        gas: gasLimit.toString()
      });

      setApprovals(prev => ({
        ...prev,
        [loanContract]: {
          ...prev[loanContract],
          [tokenType]: true
        }
      }));
      message.success(`${isCollateral ? 'Collateral' : 'crvUSD'} approval successful`);
    } catch (error) {
      console.error('Approval failed:', error);
      message.error(`${isCollateral ? 'Collateral' : 'crvUSD'} approval failed: ${error.message}`);
    } finally {
      setApproving({ ...approving, [loanContract]: { ...approving[loanContract], [tokenType]: false } });
    }
  };

  return { approvals, approving, handleApprove };
};

export default useApprovals;