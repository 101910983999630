import React, { createContext, useContext } from 'react';
import useLoanData from '../hooks/useLoanData';

const LoanDataContext = createContext();

export const LoanDataProvider = ({ account, web3, children }) => {
  const loanData = useLoanData(account, web3);
  return (
    <LoanDataContext.Provider value={loanData}>
      {children}
    </LoanDataContext.Provider>
  );
};

export const useLoanDataContext = () => {
  return useContext(LoanDataContext);
};
