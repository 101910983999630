const loanContractAddresses = [
  "0xeda215b7666936ded834f76f3fbc6f323295110a",
  "0xc510d73ad34bedeca8978b6914461aa7b50cf3fc",
  "0xac9add93364aea685be238db6c40bf53753f2cf1",
  "0x74f88baa966407b50c10b393bbd789639effe78b",
  "0x09dbdeb3b301a4753589ac6df8a178c7716ce16b",
  "0xcad85b7fe52b1939dceebee9bcf0b2a5aa0ce617",
  "0xaade9230aa9161880e13a38c83400d3d1995267b",
  "0x23f5a668a9590130940ef55964ead9787976f2cc",
  "0xa5d9137d2a1ee912469d911a8e74b6c77503bac8",
  "0x3c1350aa6faff17c87bde2015bbb45100d37dad3",
  "0x4f87158350c296955966059c50263f711ce0817c",
  "0xcf3df6c1b4a6b38496661b31170de9508b867c8e",
  "0x8c2537f1a5b1b167a960a14b89f7860dd5f7cd68",
  "0x98fc283d6636f6dcff5a817a00ac69a3add96907",
  "0xb536fea3a01c95dd09932440ec802a75410139d6",
  "0x413fd2511bad510947a91f5c6c79ebd8138c29fc",
  "0xe438658874b0acf4d81c24172e137f0ee00621b8",
  "0x1e0165dbd2019441ab7927c018701f3138114d71",
  "0x5756a035f276a8095a922931f224f4ed06149608",
  "0xA920De414eA4Ab66b97dA1bFE9e6EcA7d4219635",
  "0x4e59541306910aD6dC1daC0AC9dFB29bD9F15c67",
  "0x100dAa78fC509Db39Ef7D04DE0c1ABD299f4C6CE",
  "0xEC0820EfafC41D8943EE8dE495fC9Ba8495B15cf",
  "0x1C91da0223c763d2e0173243eAdaA0A2ea47E704",
];

export default loanContractAddresses;
