import React, { createContext, useContext } from 'react';
import useWallet from '../hooks/useWallet';

const WalletContext = createContext();

export const WalletProvider = ({ children }) => {
  const wallet = useWallet();
  return (
    <WalletContext.Provider value={wallet}>
      {children}
    </WalletContext.Provider>
  );
};

export const useWalletContext = () => {
  return useContext(WalletContext);
};
