import React from 'react';
import { Button } from 'antd';
import { useWalletContext } from '../context/WalletContext';
import { colors, buttonStyle } from '../theme';

const ConnectWallet = () => {
  const { account, error, isConnecting, connectWallet } = useWalletContext();

  const shortenAddress = (address) => {
    if (!address) return '';
    return `${address.slice(0, 5)}...${address.slice(-3)}`;
  };

  return (
    <div>
      {account ? (
        <Button style={buttonStyle} disabled>{shortenAddress(account)}</Button>
      ) : (
        <Button style={buttonStyle} onClick={connectWallet} loading={isConnecting}>
          {isConnecting ? 'Connecting...' : 'Connect Wallet'}
        </Button>
      )}
      {error && <p style={{ color: colors.woodBrown }}>{error}</p>}
    </div>
  );
};

export default ConnectWallet;