import { useState, useEffect } from 'react';
import Web3 from 'web3';

const useWallet = () => {
  const [account, setAccount] = useState(null);
  const [web3, setWeb3] = useState(null);
  const [error, setError] = useState(null);
  const [isConnecting, setIsConnecting] = useState(false);

  const connectWallet = async () => {
    setIsConnecting(true);
    setError(null);
    
    if (window.ethereum) {
      try {
        await window.ethereum.request({ method: 'eth_requestAccounts' });
        const web3Instance = new Web3(window.ethereum);
        const accounts = await web3Instance.eth.getAccounts();
        setAccount(accounts[0]);
        setWeb3(web3Instance);
        localStorage.setItem('walletConnected', 'true');
      } catch (err) {
        setError(err.message);
      }
    } else {
      setError("MetaMask not installed");
    }
    setIsConnecting(false);
  };

  useEffect(() => {
    const checkConnection = async () => {
      if (localStorage.getItem('walletConnected') === 'true' && window.ethereum) {
        const web3Instance = new Web3(window.ethereum);
        try {
          const accounts = await web3Instance.eth.getAccounts();
          if (accounts.length > 0) {
            setAccount(accounts[0]);
            setWeb3(web3Instance);
          } else {
            localStorage.removeItem('walletConnected');
          }
        } catch (err) {
          console.error("Failed to reconnect wallet:", err);
          localStorage.removeItem('walletConnected');
        }
      }
    };

    checkConnection();
  }, []);

  useEffect(() => {
    if (window.ethereum) {
      const handleAccountsChanged = (accounts) => {
        if (accounts.length > 0) {
          setAccount(accounts[0]);
        } else {
          setAccount(null);
          setWeb3(null);
          localStorage.removeItem('walletConnected');
        }
      };

      const handleChainChanged = () => {
        window.location.reload();
      };

      window.ethereum.on('accountsChanged', handleAccountsChanged);
      window.ethereum.on('chainChanged', handleChainChanged);

      return () => {
        window.ethereum.removeListener('accountsChanged', handleAccountsChanged);
        window.ethereum.removeListener('chainChanged', handleChainChanged);
      };
    }
  }, []);

  return { account, web3, error, isConnecting, connectWallet };
};

export default useWallet;