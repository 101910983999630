import React from 'react';
import { Layout, Flex, Image } from "antd";
import ConnectWallet from './components/ConnectWallet';
import LoanChecker from './components/LoanChecker';
import { WalletProvider, useWalletContext } from './context/WalletContext';
import { LoanDataProvider } from './context/LoanDataContext';
import teacoinIcon from './assets/teacoinicon.webp';
import { colors, fonts } from './theme';

const { Header, Content, Footer } = Layout;

const headerStyle = {
  backgroundColor: colors.woodBrown,
  padding: '10px 20px',
};

const titleStyle = {
  fontFamily: fonts.header,
  fontSize: '24px',  // Adjust size as needed
  margin: 0, 
  color: colors.goldCoin
};

const contentStyle = {
  backgroundColor: colors.teaGreen,
  padding: '20px',
  minHeight: 'calc(100vh - 134px)',
};

const footerStyle = {
  backgroundColor: colors.darkWood,
  color: colors.lightWood,
  textAlign: 'center',
};

const AppContent = () => {
  const { account, web3 } = useWalletContext();
  const currentYear = new Date().getFullYear();

  return (
    <Layout>
      <Header style={headerStyle}>
        <Flex justify='space-between' align='center'>
          <Flex align='center'>
            <Image
              src={teacoinIcon}
              alt="Green Tea Finance Icon"
              width={40}
              preview={false}
              style={{ marginRight: '15px' }}
            />
            <div style={titleStyle}>Green Tea Finance Emergency Exit</div>
          </Flex>
          <ConnectWallet />
        </Flex>
      </Header>
      <Content style={contentStyle}>
        {account && web3 && (
          <LoanDataProvider account={account} web3={web3}>
            <LoanChecker account={account} web3={web3} />
          </LoanDataProvider>
        )}
      </Content>
      <Footer style={footerStyle}>
        © {currentYear} Green Tea Finance
      </Footer>
    </Layout>
  );
};

function App() {
  return (
    <WalletProvider>
      <div className="App">
        <AppContent />
      </div>
    </WalletProvider>
  );
}

export default App;